<template>
  <div
    class="mb-3"
    :class="{ 'row': isHorizontal }"
  >
    <label
      v-if="label"
      class="form-label"
      :class="{
        'col': isHorizontal,
        'form-label': !isHorizontal,
        'd-flex': isHorizontal,
        'col-form-label': isHorizontal
      }"
    >
      {{ `${label}&nbsp;` || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <div
      class="btn-group w-100 h-fit-content"
      :class="{ 'is-invalid': hasErrors, 'col': isHorizontal }"
    >
      <slot name="before" />
      <input
        v-model="value"
        :class="{ 'is-invalid': hasErrors }"
        :readonly="readonly"
        class="form-control"
        :placeholder="placeHolder"
        :type="type"
        :autocomplete="disableAutocomplete ? 'new-password' : undefined"
        @blur="e => $emit('blur', e)"
      />
      <slot name="after" />
    </div>
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import { Tooltip } from 'bootstrap';
const allowedTypes = [ 'text', 'email', 'password', 'number' ];
export default {
  props: [
    'modelValue',
    'label',
    'description',
    'readonly',
    'errors',
    'inputType',
    'disableAutocomplete',
    'horizontal',
    'placeHolder',
  ],
  emits: [ 'update:modelValue', 'blur' ],
  computed: {
    type() {
      return allowedTypes.includes(this.inputType) ? this.inputType : 'text';
    },
    hasErrors() {
      return this.errors?.length > 0;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    isHorizontal() {
      return !!this.horizontal;
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  }
};
</script>
<style scoped>
.h-fit-content {
  height: fit-content;
}
</style>
